// Apparition du bouton scroll to top
// Le smooth scrolling est entièrement géré par le CSS
var btnTop = document.querySelector('.scroll-to-top')
var btnReveal = function() {
  if (window.scrollY > 300) {
    btnTop.classList.add('is-active')
  } else {
    btnTop.classList.remove('is-active')
  }
}
window.addEventListener('scroll', btnReveal)

// Récupération de la position de la souris dans une variable CSS
// Inspired by : Lea Verou - "CSS [Variable] Secrets"
var root = document.documentElement;
var header = document.querySelector(".presentation");
header.addEventListener("mousemove", evt => {
  let x = evt.clientX / innerWidth;
  let y = evt.clientY / innerHeight;

  function update() {
    root.style.setProperty("--mouse-x", x);
    root.style.setProperty("--mouse-y", y);
    requestAnimationFrame(update);
  }
  requestAnimationFrame(update);
});